<script setup>
const router = useRouter()
const route = useRoute()
const cart = useCartStore()
const auth = useAuthStore()
const config = useRuntimeConfig()
const logout = async () => {
  const ct = useCookie('ct')
  const recently = useCookie('recently')
  const woocommerce_cart_hash = useCookie('woocommerce_cart_hash')
  const woocommerce_items_in_cart = useCookie('woocommerce_items_in_cart')
  ct.value = null
  recently.value = null
  woocommerce_cart_hash.value = null
  woocommerce_items_in_cart.value = null
  auth.removeMe()
  await fetch(config.public.CMS + '/wp-json/stilles/v1/logout')
  cart.updateCart(null)
  await cart.init()
  await router.push('/')
}
const to = computed(() => {
  if (route.name !== 'index' && route.name !== 'login') {
    return { name: 'login', query: { redirect: route.path } }
  }
  else {
    return { name: 'login' }
  }
})
</script>

<template>
  <Teleport to="body">
    <div class="prenav">
      <div class="a">
        <!-- <button @click="logout">LOGOUT&nbsp;&nbsp;&nbsp;&nbsp;</button> -->
        <div class="b">
          <a href="tel:+381631093109">
            <vIcon id="support" />
            063 10 93 109
          </a>
          <a
            href="tel:+381631093109"
            class="dn"
          >
            065 78 45 537</a>
        </div>

        <div
          v-if="!auth.me"
          class="c"
        >
          <div class="cnt">
            <RouterLink
              :to="{ name: 'registracija' }"
              class="cnt fr"
            >
              <strong>NAPRAVI NALOG </strong>
            </RouterLink>
            <span class="separator">|</span>
            <RouterLink
              :to="to"
              class="cnt fl"
            >
              PRIJAVI SE
            </RouterLink>
          </div>
        </div>
        <div
          v-else
          class="cnt"
        >
          <NuxtLink
            :to="{ name: 'profil' }"
            class="profile"
          >
            <span v-text="auth.me.user_nicename || auth.me.user_display_name || auth.me.user_email" />
          </NuxtLink>
          <span
            class="cnt"
            @click="logout"
          >
            ODJAVI SE
          </span>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.admin {
  background-color: var(--brand-color);
  color: white;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 3px;
  margin: 0 4px 0 10px;
}

.profile {
  text-transform: uppercase;

  & img {
    width: 16px;
    height: 16px;
  }
}

.prenav {
  height: 32px;

  & .a {
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    font-size: 12px;
    margin: 0 auto;
    font-weight: 600;
    cursor: pointer;
    max-width: 1488px;

    & .fl {
      margin-right: 2px;
    }
  }
}

a {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: rgb(var(--color-rgb) / .5);
  margin-right: 16px;

  & svg {
    margin-right: 8px;
  }

  &+a {
    margin-right: 0;
  }
}

.b {
  display: flex;
  color: rgb(var(--color-rgb) / .5);
}

.c {
  font-size: 13px;
  color: rgb(var(--color-rgb) / .5);
  display: flex;
  align-items: center;

  & svg {
    margin-left: 8px;
  }
}

.cnt {
  display: flex;
  align-items: center;

  & a {
    margin-right: 8px;
  }
}

.separator {
  padding-right: 8px;
}

@media (max-width: 512px) {
  .dn {
    display: none;
  }

  .prenav .a {
    padding: 0 26px;
  }
}
</style>
