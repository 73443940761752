<script setup>
import { okovi, plocasti } from '#nuxt-prepare'

const config = useRuntimeConfig()
const internal = useInternal()

const plocastiParent = computed(() => plocasti.filter(x => x.parent === 0))

const usluge = ref([])

const close = () => {
  internal.useMenu(false)
}

watch(() => internal.menu, (x) => {
  const elem = document.body
  elem.style.overflowY = x ? 'hidden' : 'auto'
})

const a = ref(null)
const parent = ref(null)

const set = (x) => {
  a.value = a.value === null ? x : null
}
const setParent = (x) => {
  parent.value = parent.value === null ? x : null
}
const subpages = async (x) => {
  a.value = a.value === null ? x : null
  const aaa = await $fetch(config.public.CMS + '/wp-json/wp/v2/pages', {
    params: {
      parent: 5441,
      _fields: 'title,slug,id'
    }
  })
  usluge.value = aaa
}
</script>

<template>
  <Teleport to="body">
    <Transition
      name="fade"
      appear
    >
      <!-- @click="close" -->
      <div
        v-show="internal.menu"
        class="cart"
      >
        <Transition appear>
          <div
            v-show="internal.menu"
            class="cart_aside"
          >
            <div class="scroll">
              <div class="header">
                <svg
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                  fill="var(--color)"
                  @click="close"
                >
                  <path
                    d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                  />
                </svg>
              </div>
              <div>
                <div class="expand">
                  <NuxtLink
                    to="/plocasti-materijali"
                    @click.close="close"
                  >
                    PLOČASTI MATERIJALI
                  </NuxtLink>
                  <span
                    class="toggle"
                    @click.prevent="setParent('plocasti-materijali')"
                  >
                    <svg
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                      fill="rgb(var(--color-rgb) / .5)"
                    >
                      <path
                        v-if="a !== 'plocasti-materijali'"
                        d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                      />
                      <path
                        v-else
                        d=" M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  v-show="parent === 'plocasti-materijali'"
                  class="links indent"
                >
                  <template
                    v-for="ploca, keyPloca in plocastiParent"
                    :key="keyPloca"
                  >
                    <NuxtLink
                      v-if="!Boolean(ploca.subcategories.length)"
                      :to="'/plocasti-materijali/' + ploca.slug"
                      class="link"
                      @click.close="close"
                    >
                      {{ ploca.name }}
                    </NuxtLink>
                    <template v-else>
                      <div class="expand">
                        <NuxtLink
                          :to="'/plocasti-materijali/' + ploca.slug"
                          @click.close="close"
                        >
                          {{ ploca.name }}
                        </NuxtLink>
                        <span
                          class="toggle"
                          @click="set(ploca.id)"
                        >
                          <svg
                            height="24"
                            viewBox="0 -960 960 960"
                            width="24"
                            fill="rgb(0 0 0 / .66)"
                          >
                            <path
                              v-if="a !== ploca.id"
                              d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                            />
                            <path
                              v-else
                              d=" M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div v-if="a === ploca.id">
                        <NuxtLink
                          v-for="sub in ploca.subcategories"
                          :key="'plocasti' + sub.term_id"
                          :to="'/plocasti-materijali/' + ploca.slug + '/' + sub.slug"
                          class="a indent"
                          @click.close="close"
                        >
                          {{ sub.name }}
                        </NuxtLink>
                      </div>
                    </template>
                  </template>

                  <div class="expand">
                    <NuxtLink
                      :to="{ name: 'usluge' }"
                      @click.close="close"
                    >
                      Usluge
                    </NuxtLink>
                    <span
                      class="toggle"
                      @click.prevent="subpages('usluge')"
                    >
                      <svg
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                        fill="rgb(0 0 0 / .66)"
                      >
                        <path
                          v-if="a !== 'usluge'"
                          d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                        />
                        <path
                          v-else
                          d=" M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div
                    v-show="a === 'usluge'"
                    class="a indent"
                  >
                    <NuxtLink
                      v-for="usluga in usluge"
                      :key="'usluga' + usluga.id"
                      :to="'/usluge/' + usluga.slug"
                      class="link"
                      @click.close="close"
                    >
                      {{ usluga.title.rendered }}
                    </NuxtLink>
                  </div>
                </div>
                <div class="expand">
                  <NuxtLink
                    to="/okov-za-namestaj"
                    @click.close="close"
                  >
                    OKOV ZA NAMEŠTAJ
                  </NuxtLink>
                  <span
                    class="toggle"
                    @click.prevent="setParent('okov-za-namestaj')"
                  >
                    <svg
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                      fill="rgb(var(--color-rgb) / .5)"
                    >
                      <path
                        v-if="a !== 'okov-za-namestaj'"
                        d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                      />
                      <path
                        v-else
                        d=" M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
                      />
                    </svg>
                  </span>
                </div>

                <div
                  v-show="parent === 'okov-za-namestaj'"
                  class="links indent"
                >
                  <NuxtLink
                    v-for="okov in okovi"
                    :key="'okov' + okov.id"
                    :to="'/okov-za-namestaj/' + okov.slug"
                    class="link"
                    @click.close="close"
                  >
                    {{ okov.name }}
                  </NuxtLink>
                </div>

                <NuxtLink
                  :to="{ name: 'namestaj' }"
                  class="fx mh"
                  @click.close="close"
                >
                  NAMEŠTAJ
                </NuxtLink>
                <a
                  href="https://masinezaobradudrveta.com"
                  class="fx mh"
                >
                  MAŠINE ZA OBRADU DRVETA</a>
                <NuxtLink
                  :to="{ name: 'o-nama' }"
                  class="fx mh"
                  @click.close="close"
                >
                  O NAMA
                </NuxtLink>

                <NuxtLink
                  :to="{ name: 'zaposlenje' }"
                  class="fx mh"
                  @click.close="close"
                >
                  ZAPOSLENJE
                </NuxtLink>
                <NuxtLink
                  :to="{ name: 'kontakt' }"
                  class="fx mh"
                  @click.close="close"
                >
                  KONTAKT
                </NuxtLink>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.links {
  display: flex;
  flex-direction: column;

  & a {
    font-size: 13px;
    padding: 4px 24px 4px 9px;
    color: var(--color);
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.router-link-exact-active {
      color: var(--brand-color);
      font-weight: 600;
      /* &::before {
        content: "👉 ";
        margin-left: -2.4ch;
      } */
    }

    &.indent {
      font-size: 12px;
      padding-left: 24px;
    }

    &.arrow {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.expand {
  display: grid;
  grid-template-columns: auto 24px;
}

.indent {
  padding: 4px 0 4px 24px;

  & a {
    font-weight: 400;
    padding: 8px 0;
    &::before {
      content: "- "
    }
  }
}

.toggle {
  position: relative;
  cursor: pointer;
  user-select: none;

  & svg {
    vertical-align: middle;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 50%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 256ms linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

a {
  display: block;
  font-weight: 600;
  padding: 8px 0;
  font-size: 14px;
  color: rgb(--color-rgb);
  & small {
    display: block;
    font-weight: 400;
    font-size: 10px;
    color: rgb(var(--color-rgb) / .5);
  }

  &.router-link-exact-active {
    color: var(--brand-color);
  }

  /* margin-bottom: 8px; */
}

.scroll {
  height: 100%;
  /* overflow-x: hidden; */
  overflow-y: auto;
  padding-right: 24px;
}

.v-enter-active,
.v-leave-active {

  transition: transform 256ms ease;
}

.v-enter-from,
.v-leave-to {
  transform: translate3d(100%, 0, 0);
}

:deep(.quantity) {
  width: 100px;
}

.cnt {
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}

.cart_price {
  text-decoration: line-through;
  color: tomato;
  line-height: 1;
  padding-left: 8px;
  font-size: 14px;
}

.items {
  padding-top: 32px;
}

.name {
  display: block;
  font-size: 13px;
}

strong {
  font-weight: 800;
  font-size: 13px;
}

.cart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgb(var(--color-rgb) / .5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  /* backdrop-filter: blur(3px); */
}

.cart_aside {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  bottom: 0;
  background-color: var(--background-color);
  color: var(--color);
  padding: 44px 0 44px 24px;
  z-index: 21;
}

button {
  display: grid;
  place-items: center;
  height: 56px;
  background-color: black;
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 1;
  padding: 0 16px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: rgba(0 0 0 / .25) 0px 13px 27px -5px, rgba(0 0 0 / .5) 0px 8px 16px -8px;

  &:active {
    transform: scale(.95);
    box-shadow: none;
  }

  &.outline {
    margin-top: 16px;
    background-color: white;
    color: black;
    box-shadow: none;
    border: 1px solid;
  }

  &:disabled {
    box-shadow: none;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  font-weight: 800;

  & svg {
    cursor: pointer;
  }
}

.remove {
  font-weight: 600;
  font-size: 14px;
}

@media (max-width: 512px) {
  .cart_aside {
    top: 88px;
    width: 100%;

  }
  .v-enter-from,
  .v-leave-to {

    transform: translate3d(0, 100%, 0);
  }
  a {
    font-size: 16px;
  }
}

@media (hover:hover) {
  .toggle:hover {
    &::after {
      background-color: rgb(0 0 0 / .06);
    }
  }
}
</style>
