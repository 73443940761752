<script setup>
// const dialog = ref(false)
const internal = useInternal()
const cart = useCartStore()
const showModal = ref(false)
const menu = () => {
  internal.useMenu(true)
}
const openModal = () => {
  document.body.style.overflowY = 'hidden'
  showModal.value = true
}
const closeModal = () => {
  document.body.style.overflowY = 'auto'
  showModal.value = false
}
const navigate = () => {
  closeModal()
  window.location.href = 'https://masinezaobradudrveta.com'
}
</script>

<template>
  <div
    class="nav"
  >
    <nav ref="header">
      <div class="cnt">
        <NuxtLink
          :to="{ name: 'index' }"
          aria-label="Naslovna"
        >
          <img
            src="/images/logo.png"
            alt="Stilles"
          >
        </NuxtLink>
      </div>
      <div class="nav_right">
        <!-- <NuxtLink :to="{ name: 'index' }" class="fx mh">
          /
        </NuxtLink> -->
        <NuxtLink
          :to="{ name: 'plocasti-materijali' }"
          class="fx mh"
        >
          PLOČASTI <span>MATERIJALI</span>
        </NuxtLink>
        <NuxtLink
          :to="{ name: 'okov-za-namestaj' }"
          class="fx mh"
        >
          OKOV <span>ZA NAMEŠTAJ</span>
        </NuxtLink>
        <NuxtLink
          :to="{ name: 'namestaj' }"
          class="fx mh"
        >
          NAMEŠTAJ
        </NuxtLink>
        <NuxtLink
          :to="{ name: 'usluge' }"
          class="fx mh"
        >
          USLUGE
        </NuxtLink>
        <a
          href="https://masinezaobradudrveta.com"
          class="fx mh vam"
          @click.prevent="openModal"
        >
          MAŠINE
        </a>
        <NuxtLink
          :to="{ name: 'o-nama' }"
          class="fx mh"
        >
          O NAMA
        </NuxtLink>
        <NuxtLink
          :to="{ name: 'kontakt' }"
          class="fx mh"
        >
          KONTAKT
        </NuxtLink>

        <NuxtLink
          :to="{ name: 'korpa' }"
          :class="['cart', { has: !cart.loading && cart.quantitySum !== 0 }]"
          aria-label="Korpa"
        >
          <span class="fx tar">
            {{ formatCurrency((cart.cart?.totals?.total_price ?? 0) / 100) }}
          </span>
          <vSpinner v-if="cart.loading" />
          <vIcon
            v-else
            :id="!Boolean(cart?.quantitySum) ? 'cart-outline' : 'cart'"
            class="tr"
            :fill="!Boolean(cart?.quantitySum) ? 'rgb(var(--color-rgb) / .5)' : 'var(--brand-color)'"
          />
        </NuxtLink>
        <vIcon
          id="menu"
          class="menu"
          @click="menu"
        />
      </div>
    </nav>
    <vAsideMenu />
  </div>
  <!-- <Search :show="dialog" /> -->
  <transition name="modal">
    <div
      v-if="showModal"
      class="modal"
    >
      <div class="modal-content">
        <h3>Mašine za obradu drveta</h3>
        <p>Jeste li sigurni da želite da odete?</p>
        <div class="cta">
          <button
            class="secondary"
            @click="closeModal"
          >
            NAZAD
          </button>
          <button @click="navigate">
            NASTAVI
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.menu {
  cursor: pointer;
  color: rgb(var(--color-rgb) / .5);
}

.tr {
  transform: translateX(5px);
}

.vam {
  display: flex;
  align-items: center;
  line-height: 1;

  & svg {
    margin-left: 4px;
  }
}

.cta {
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
}

button {
  font-weight: 600;
  font-size: 14px;

  &.secondary {
    color: rgb(var(--color-rgb) / .5);
  }

  &+button {
    margin-left: 16px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--color-rgb) / .5);
  /* backdrop-filter: blur(5px); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;

  & h3 {
    font-weight: 800;
    font-size: 18px
  }

}

.dh {
  display: none;
}

.nav {
  position: sticky;
  top: 0;
  z-index: 10;
  pointer-events: none;
  --duration: 0.5s;
  --timing: ease;
  background-color: var(--background-color);
  transition: color var(--duration) var(--timing), background-color var(--duration) var(--timing);
}

nav {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  /* outline: 1px dashed; */
  pointer-events: none;
  max-width: 1488px;
  margin: 0 auto;
  pointer-events: auto;

}

.fx {
  /* text-align: center; */
  line-height: 1;
  font-weight: 500;
  color: rgb(var(--color-rgb) / .5);
}

.router-link-active {
  color: var(--brand-color);

  & .fx {
    color: var(--color);
  }

  & small {
    color: var(--color);
  }
}

.title {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: 800;
  font-size: 24px;
}

.count {
  font-weight: 800;
  font-size: 24px;
  font-variant-numeric: tabular-nums;
  color: rgb(var(--color-rgb) / .24);
  padding-right: 4px;
}

.nav_right {
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 13px;
}

.cart {
  display: flex;
  align-items: center;
  gap: 4px;

  & span {
    font-size: 14px;
    /* font-variant-numeric: tabular-nums; */
  }

  &.has {
    & .fx {
      color: var(--color);
    }
  }
}

strong {
  font-weight: 700;
}

img {
  width: 110px;
  height: auto;
  vertical-align: middle;
  /* filter: grayscale(); */
}

.cnt {
  display: flex;
  align-items: center;

  & svg {
    margin-right: 4px;
  }
}

.search {
  padding: 0 8px 0 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 16px;
  background-color: rgb(0 0 0 / .06);
  height: 36px;
  line-height: 1;
  color: #6d6d6d;

  & svg {
    margin-left: 8px;
  }
}

.fr {
  padding-right: 0;
}

.separator {
  padding: 0 4px;
  color: rgb(var(--color-rgb) / .12);
}

@media (hover:hover) {
  .menu:hover,
  .fx:hover {
    color: var(--color);
  }
}

@media (max-width: 1340px) {
  .nav_right {
    gap: 16px;
  }

  .fx small.mh,
  .mh {
    display: none;
  }

  .dh {
    display: flex;
  }

  .search {
    background-color: transparent;
    padding: 0 4px 0 0;

    & span {
      display: none;
    }
  }
}
</style>
